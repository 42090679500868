import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({ enabled }) => (!enabled ? '24px' : 0)};
  border-top: 1px solid #d2d4d7;
  padding-top: 40px;

  @media screen and (max-width: 1025px) {
    align-items: flex-start;
  }
`;

export const HeaderContainer = styled.div`
  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: 1025px) {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const VehicleArea = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
`;

export const BadgeArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 332px);
  align-items: flex-start;
  column-gap: 144px;

  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 316px);
    column-gap: 160px;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0 100px;
  max-width: 1200px;
  width: 100%;
  padding: 0 32px;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.6rem;

  @media screen and (max-width: 1025px) {
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
`;

export const BadgeAreaTitle = styled.div`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.64px;
  text-align: left;
  color: #032470;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const BadgeAreaSubtitle = styled.div`
  font-family: Rubik;
  font-size: 0.9rem;
  font-weight: 400;
  color: #2f3741;
  margin-top: 10px;
  a {
    color: #032470;
    text-decoration: underline;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px !important;
  font: 400 normal 16px/1.5em 'Roboto', sans-serif;
  color: #1a2331;
  text-align: end;
  border-bottom: 1px solid #d2d4d7;
`;
