import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { MdAddCircle } from 'react-icons/md';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Layout, Box, Flex, Text } from '../components';
import { Button } from '../components/enhanced/Button';
import { FileInput } from '../components/abstract/forms/file-input';
import { TextInput, CPFInput, RGInput } from '../components/formik-fields';
import { BarWrapper } from '../components/topbar/bar-wrapper';
import { getFileUploadURL, registerTaxId } from '../services/taxids';
import appConstants from '../constants/appConstants';
import { ErrorMessage } from '../components/abstract/styled/forms/common-styles';
import Modal from '../components/enhanced/Modal';
import { Dialog } from '../components/dialog';
import Loading from '../components/shared/loading/Loading';
import { errorsTranslation } from '../utils/taxProfile';
import axios from 'axios';

const FormRow = props => <Box mb={3} {...props} />;

const DocumentsHelpText = () => (
  <>
    Precisaremos de um documento que contenha o seu <strong>RG</strong> e seu <strong>CPF</strong>{' '}
    para validarmos o seu cadastro.
  </>
);

const HelpText = styled.span`
  color: #82878e;
  font-size: 0.8rem;
`;

const InputField = styled(Field)`
  width: ${({ width }) => width}%;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const FORM_SUBMITTED = 1;
const FORM_UPLOADING_FILES = 2;
const FORM_REGISTERING_TAX_ID = 3;

const statusMessages = {
  [FORM_SUBMITTED]: '',
  [FORM_UPLOADING_FILES]: 'Enviando documentos...',
  [FORM_REGISTERING_TAX_ID]: 'Registrando novo perfil...',
};

const checkCPFValidity = cpf => {
  const digits = cpf
    .replace(/\D/g, '')
    .split('')
    .map(Number);

  let sum1 = 0;
  for (let i = 1; i <= digits.length - 2; i++) {
    sum1 = sum1 + digits[i - 1] * i;
  }
  const remainder1 = sum1 % 11;
  const firstCheckDigit = remainder1 === 10 ? 0 : remainder1;

  let sum2 = 0;
  for (let i = 0; i <= 9; i++) {
    if (i < 9) {
      sum2 = sum2 + digits[i] * i;
      continue;
    }

    sum2 = sum2 + firstCheckDigit * i;
  }
  const remainder2 = sum2 % 11;
  const secondCheckDigit = remainder2 === 10 ? 0 : remainder2;

  return (
    digits[digits.length - 2] === firstCheckDigit && digits[digits.length - 1] === secondCheckDigit
  );
};

const TaxProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'O Nome deve ter pelo menos 3 caracteres')
    .required('O Nome é obrigatório'),
  cpf: Yup.string()
    .matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/, 'O CPF não tem o formato correto')
    .required('O CPF é obrigatório')
    .test('cpf-validation', 'O CPF já está registrado no sistema', async cpf => {
      try {
        await validateCpfApi(cpf);
        return true;
      } catch (error) {
        return false;
      }
    }),
  rg: Yup.string()
    .required('O RG é obrigatório')
    .test('rg-validity', 'O RG não pode ser igual ao CPF', function(rg, context) {
      if (!rg) return true;
      const cleanedCPF = context.parent.cpf ? context.parent.cpf.replace(/[^\w\s]/gi, '') : '';
      return rg !== context.parent.cpf && rg !== cleanedCPF;
    }),
  nickName: Yup.string()
    .max(10, 'O Apelido deve ter no máximo 10 caracteres')
    .required('O Apelido é obrigatório'),
  docs: Yup.array()
    .min(1, 'Deve ser anexado pelo menos um documento')
    .max(4, 'No máximo 4 documentos podem ser anexados')
    .nullable()
    .required('Deve ser anexado pelo menos um documento'),
});

export const validateCpfApi = async cpf => {
  try {
    const response = await axios.post(appConstants.API.validateCpf, cpf);

    if (response.status === 200 && response.data.message === 'CPF válido e não registrado.') {
      return true;
    }
    throw new Error(response.data.message || 'Erro ao validar CPF');
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao validar CPF');
  }
};

export const TaxProfileForm = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const userId = useSelector(state => state.authentication.user.id);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const mutation = useMutation(registerTaxId, {
    onSuccess: () => {
      queryClient.invalidateQueries('taxids');
    },
  });

  return (
    <Layout>
      <Flex flexDirection="column" width={1}>
        <BarWrapper
          color="primaries.Taxi"
          height={{ sm: '2.5rem', md: '3.7rem' }}
          fontSize={{ sm: 2, md: 5 }}
          px={1}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          Novo cadastro de dados para emissão do DUT e da Nota Fiscal
        </BarWrapper>
        <Box className="container" mx="auto" p={1} width={1} css={{ maxWidth: '960px' }}>
          <Formik
            initialValues={{
              name: '',
              cpf: '',
              rg: '',
              nickName: '',
              docs: null,
            }}
            validationSchema={TaxProfileSchema}
            onSubmit={async (values, { setStatus }) => {
              const { docs = [], name, cpf, rg, nickName } = values;
              const docRequests = docs.map(async doc => {
                const fileExtension = doc.name.match(/\.([0-9a-z]+)$/i)[1];
                try {
                  const response = await getFileUploadURL(userId, fileExtension);
                  const {
                    data: { key, uploadURL },
                  } = response.data;
                  return {
                    key,
                    uploadURL,
                    fileName: doc.name,
                  };
                } catch (error) {
                  throw new Error('An error ocurred fetching the uploadURL from S3.');
                }
              });

              try {
                setStatus(FORM_UPLOADING_FILES);
                const uploadURLs = await Promise.all(docRequests);
                const uploads = uploadURLs.map(async upload => {
                  const [file] = docs.filter(doc => doc.name === upload.fileName);
                  // TODO: Check if we can send the file (File) as is, as File is already an instance of Blob.
                  const buffer = await file.arrayBuffer();
                  const blobData = new Blob([new Uint8Array(buffer)], { type: file.type });
                  return fetch(upload.uploadURL, {
                    method: 'PUT',
                    body: blobData,
                  }).then(response => {
                    if (!response || response?.ok === false) {
                      throw Error('Upload file error');
                    } else {
                      return response;
                    }
                  });
                });
                await Promise.all(uploads);
                setStatus(FORM_REGISTERING_TAX_ID);
                await mutation.mutateAsync({
                  type: appConstants.TaxIdType.CPF,
                  fullName: name,
                  taxIdentificationNumber: cpf,
                  personalIdRG: rg,
                  nickName,
                  supportImages: uploadURLs.map(({ key }) => key),
                });

                setStatus(FORM_SUBMITTED);
              } catch (error) {
                setShowErrorAlert(true);
                setErrorMessage(error.message);
              }
            }}
          >
            {({ isSubmitting, isValid, dirty, status }) => (
              <>
                <Form>
                  <FormRow>
                    <InputField
                      component={TextInput}
                      id="name"
                      type="text"
                      name="name"
                      labelText="Nome completo"
                      width="33.33"
                    />
                  </FormRow>
                  <FormRow>
                    <InputField
                      component={CPFInput}
                      id="cpf"
                      type="text"
                      name="cpf"
                      labelText="CPF"
                      width="25"
                    />
                  </FormRow>
                  <FormRow>
                    <InputField
                      component={RGInput}
                      id="rg"
                      type="text"
                      name="rg"
                      labelText="RG"
                      width="25"
                    />
                  </FormRow>
                  <FormRow>
                    <InputField
                      component={TextInput}
                      id="nickName"
                      type="text"
                      name="nickName"
                      labelText="Apelido"
                      maxLength="10"
                      formText="O apelido será utilizado para que você possa identificar com qual perfil você está participando em um leilão."
                      width="33.33"
                    />
                  </FormRow>
                  <FormRow>
                    <Field name="docs">
                      {({ field, form: { setFieldValue }, meta }) => {
                        const { onChange, value, ...props } = field;
                        return (
                          <>
                            <FileInput
                              id="tax-id-docs"
                              labelText="Documentos"
                              formText={<DocumentsHelpText />}
                              multiple
                              accept=".png,image/png,.jpg,.jpeg,.pjpeg,.pjp,image/jpeg,.pdf,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.apng,image/apng,.svg,image/svg+xml,.webp,image/webp"
                              onChange={files => {
                                setFieldValue(field.name, files);
                              }}
                              {...props}
                            />
                            <HelpText>
                              *Arquivos aceitos: .png, .jpg, .jpeg, .pdf, .doc e .docx
                            </HelpText>
                            {meta.touched && meta.error && (
                              <ErrorMessage>{meta.error}</ErrorMessage>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </FormRow>
                  <Flex justifyContent="flex-end" flexWrap="wrap">
                    <Button as={Link} to="/perfis" variant="ghost.primary" replace>
                      Cancelar
                    </Button>
                    <Button
                      ml={2}
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting || !isValid || !dirty || status === FORM_SUBMITTED}
                    >
                      <Button.Icon>
                        <MdAddCircle />
                      </Button.Icon>
                      Enviar para validação
                    </Button>
                  </Flex>
                </Form>
                <Modal show={status === FORM_SUBMITTED || isSubmitting}>
                  {isSubmitting ? (
                    <Flex
                      p={4}
                      minWidth={300}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Loading />

                      <Text mt={1} fontSize={1}>
                        {statusMessages[status]}
                      </Text>
                    </Flex>
                  ) : (
                    <Dialog
                      header="Solicitação realizada com sucesso!"
                      onCTAClick={() => history.push('/perfis')}
                    >
                      <p>Prontinho, você acabou de solicitar o cadastro de um novo perfil.</p>
                      <p>
                        Assim que finalizarmos a validação dos dados, o perfil será habilitado para
                        que você utilizado em leilões.
                      </p>
                    </Dialog>
                  )}
                </Modal>
                <Modal show={showErrorAlert}>
                  <Dialog
                    variant="error"
                    header="Ops, tivemos um problema!"
                    onCTAClick={() => setShowErrorAlert(false)}
                    primaryBtnLabel="Entendi"
                    primaryBtnVariant="error"
                  >
                    <p>Tivemos um problema ao realizar o cadastro</p>
                    <p>{errorsTranslation(errorMessage)}</p>
                  </Dialog>
                </Modal>
              </>
            )}
          </Formik>
        </Box>
      </Flex>
    </Layout>
  );
};
