import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  height: 100%;
  padding-right: 64px;
  margin-bottom: 30px;
  align-items: center;
  background-color: white;
  padding-top: 10px;

  @media screen and (max-width: 1025px) {
    display: flex;
    padding: 10px 0px 0px 0px;
    margin-bottom: 15px;
  }
`;

export const CardContainer = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`;
export const DividerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 64px;

  @media screen and (max-width: 1025px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const GreenArrowContainer = styled.div`
  margin-top: 0;
  padding-left: 44px;
  @media screen and (max-width: 1025px) {
    padding-left: 15px;
  }
`;

export const DropDownContainer = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  height: 100%;
  padding: 12px 24px 0px 27px;
  background-color: white;
  flex-direction: column;

  @media screen and (max-width: 1025px) {
    display: flex;
    padding: 0px 0px 0px 0px;
  }
`;

export const CarContainer = styled.div`
  display: flex;
  max-width: 92%;
  width: 92%;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    gap: 6px 0;
    width: 100%;
    align-items: stretch;
  }
`;

export const TextContainer = styled.div`
  max-width: 100%;
  width: 100%;
  padding-left: 20px;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    padding-left: 8px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  max-width: 92%;
  width: 92%;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  padding-bottom: 10px;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    padding-top: 0px;
    align-items: normal;
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
`;

export const UserContainerMobile = styled.div`
  display: flex;
  max-width: 90%;
  width: 90%;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: row;
  }
`;

export const AvaliationLinkContainer = styled.div`
  display: flex;
  max-width: 92%;
  width: 92%;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: row;
  }
`;

export const CarContent = styled.div`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 16px;
  color: #2f3741;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
  @media screen and (max-width: 1025px) {
    line-height: normal;
    width: 80%;
  }
`;

export const UserName = styled.div`
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 14px;
  color: #8b8d91;
  padding-top: 5px;
  @media screen and (max-width: 1025px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
  }
`;

export const UserNameDiv = styled.div`
  width: 650px;
`;

export const StatusContent = styled.div`
  background-color: ${props => props.backgroundColor};
  border-radius: 3px;
  padding: 5px;
  display: flex;
  max-width: fit-content;
  flex-direction: row;
  align-items: center;
`;
export const OtherInformations = styled.div`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 14px;
  color: #5d6066;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1025px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CarImg = styled.div`
  width: 19%;
  padding-top: 13%;
  background-color: #e5eaeb;

  @media (min-width: 992px) {
    width: 5rem;
    padding-top: 3.8rem;
  }
  @media screen and (max-width: 1025px) {
    width: 16%;
    padding-top: 11%;
  }
  position: relative;
  border-radius: 50%;
  img {
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
`;

export const IconsDiv = styled.div`
  padding-right: 2px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const AuctionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  width: 100%;
`;

export const AuctionInfoContainerId = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  width: 100%;
  @media screen and (max-width: 1025px) {
    padding-top: 10px;
  }
`;

export const CalendarDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5;

  @media screen and (max-width: 1025px) {
    padding-top: 0;
  }
`;

export const MobileSlugContainer = styled.div`
  @media screen and (max-width: 1025px) {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    position: relative;
  }
`;

export const MobileSlugContainerArrow = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: row;
    width: 110%;
    padding-bottom: 5px;
  }
`;

export const StatusIcon = styled.div`
  padding-right: 5px;
  display: flex;
  align-items: center;
`;

export const TagText = styled.div`
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #171c24;
  padding-top: 2px;
`;

export const InfosDiv = styled.div`
  width: 80%;
`;

export const InfoLinkText = styled.a`
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #00a81f;
  text-decoration: underline;
  padding-top: 2px;
  width: 100%;
  justify-content: end;
  display: flex;
`;

export const Divider = styled.div`
  display: inline-block;

  width: 100%;
  height: 1px;

  margin: 0.8rem 0 0;

  background-color: #b9bbbd;
`;

export const UserNameCalendarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TagDeskDiv = styled.div`
  padding-bottom: 10px;
  padding-right: 10px;
  @media screen and (max-width: 1025px) {
    padding-bottom: 5px;
  }
`;

export const SlugImageDiv = styled.a`
  flex-direction: row;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SlugCalendarContainer = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  width: 92%;
`;

export const ArrowCalendarDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserContainerTag = styled.div`
  padding-left: 100px;
  width: 92%;
`;

export const MultiTagContainer = styled.a`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;

export const AdditionalServicesContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 1025px) {
    padding-top: 15px;
  }
`;

export const PartnerEvaluatedImage = styled.img`
  width: 150px;
  top: -32px;
  left: 14px;
  position: absolute;
  z-index: 3;

  @media screen and (max-width: 1025px) {
    width: 100px;
    top: -22px;
    left: 14px;
  }
`;
