import React from 'react';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

import {
  Container,
  VehicleArea,
  AreaTitle,
  Row,
  Column,
  VideoContainer,
  AreaTitleContainer,
  AreaSubtitle,
} from './styles';

export const VehicleD2DVideos = props => {
  const { updatedAuction } = props;

  return (
    <Container>
      <VehicleArea>
        <AreaTitleContainer>
          <AreaTitle>
            <SmartDisplayIcon sx={{ marginRight: 1 }} />
            Vídeos
          </AreaTitle>
        </AreaTitleContainer>
        <AreaSubtitle>Confira motor, escapamento e câmbio.</AreaSubtitle>
        <Row>
          <Column>
            <VideoContainer>
              <iframe
                src={updatedAuction?.videos?.[0]}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vídeo 1"
              />
            </VideoContainer>
          </Column>
          <Column>
            <VideoContainer>
              <iframe
                src={updatedAuction?.videos?.[1]}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vídeo 2"
              />
            </VideoContainer>
          </Column>
          <Column>
            <VideoContainer>
              <iframe
                src={updatedAuction?.videos?.[2]}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vídeo 3"
              />
            </VideoContainer>
          </Column>
        </Row>
      </VehicleArea>
    </Container>
  );
};
