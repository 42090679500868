import React, { useCallback, useMemo, useState } from 'react';
import { TiLocation } from 'react-icons/ti';

import {
  Container,
  SpanRow,
  List,
  ListItem,
  Badge,
  BadgeIcon,
  BadgeContent,
  BadgeTitle,
  BadgeDescription,
  Divider,
  Row,
  Column,
  VehicleArea,
  BadgeArea,
  ScoreLocation,
  BadgeAreaTitle,
} from './styles';

import Cautelar from '../../../../images/icons/cautelar.svg';
import Km from '../../../../images/icons/km.svg';
import Margem from './images/margem.svg';
import Giro from './images/giro.svg';
import Longarina from '../../../../images/icons/badge_longarina.svg';
import Colunas from '../../../../images/icons/badge_colunas.svg';
import Cambio from '../../../../images/icons/badge_cambio.svg';
import Scanner from '../../../../images/icons/badge_scanner.svg';
import UberRJ from '../../../../images/icons/badge_uber_rj.svg';
import UberSP from '../../../../images/icons/badge_uber_sp.svg';
import Uber from '../../../../images/icons/badge_uber.svg';

import { ReferencePrices } from '../ReferencePrices';
import { useFlag } from '@unleash/proxy-client-react';
import MaximumQuality from '../MaximumQuality';
import { DocumentResearch } from '../DocumentResearch';
import { DutLabel } from './styles';

const fuel = [
  'GASOLINA',
  'FLEX',
  'ETANOL',
  'DIESEL',
  'HIBRIDO',
  'HYBRID',
  'ELETRICO',
  'GASOLINA/GNV',
  'GASOLINA/ETANOL',
  'GASOLINA/ETANOL/GNV',
  'GASOLINA/ELETRICO',
  'GASOLINA/ETANOL/ELETRICO',
  'HYBRID FLEX',
  'HIBRIDO FLEX',
  'JETFLEX GASOLINA',
];

export const Summary = props => {
  const { auction, summary, marketPrice, updatedAuction } = props;
  const shouldShowFipeWebPrices = useFlag('fipe-web-prices');

  const [size, setSize] = useState(window.screen.width);

  /** BADGEs UNLEASH FLAGS */
  const flagBadgeCautelar = useFlag('badge-cautelar');
  const flagBadgeLongarina = useFlag('badge-longarina');
  const flagBadgeColunas = useFlag('badge-colunas');
  const flagBadgeKm = useFlag('badge-km');
  const flagBadgeCambio = useFlag('badge-cambio');
  const flagBadgeScanner = useFlag('badge-scanner');
  const flagBadgeMargem = useFlag('badge-margem');
  const flagBadgeRevenda = useFlag('badge-giro');
  const flagBadgeUberSP = useFlag('badge-uber-sp');
  const flagBadgeUberRJ = useFlag('badge-uber-rj');
  const flagBadgeUberBoothStates = useFlag('badge-uber');

  const formmatDescription = description => {
    const parts = description.split(':');
    return parts.length >= 2 ? parts[1].trim() : '';
  };

  const injuctionNote = summary?.evaluation?.highlights?.map(({ icon, description }) =>
    icon === 11 ? description : null
  );

  const keySet = summary?.evaluation?.highlights?.map(({ icon, description }) =>
    icon === 12 ? formmatDescription(description) : null
  );

  const manualBooklet = summary?.evaluation?.highlights?.map(({ icon, description }) =>
    icon === 13 ? formmatDescription(description) : null
  );

  const shouldShowBadgeUberBoothStates =
    auction?.vehicle?.canHaveUberSPBadge &&
    auction?.vehicle?.canHaveUberRJBadge &&
    flagBadgeUberBoothStates;

  const shouldShowBadgeUberSP =
    auction?.vehicle?.canHaveUberSPBadge && flagBadgeUberSP && !shouldShowBadgeUberBoothStates;

  const shouldShowBadgeUberRJ =
    auction?.vehicle?.canHaveUberRJBadge && flagBadgeUberRJ && !shouldShowBadgeUberBoothStates;

  const shouldShowCautelar = auction?.vehicle?.precautionaryReport && flagBadgeCautelar;

  const shouldShowLongarina =
    auction?.vehicle?.canHaveCarSparBadge && flagBadgeLongarina && !shouldShowCautelar;

  const shouldShowColunas =
    auction?.vehicle?.canHaveColumnBadge && flagBadgeColunas && !shouldShowCautelar;

  const BADGE_STATUS = {
    BadgeCautelar: shouldShowCautelar,
    BadgeLongarina: shouldShowLongarina,
    BadgeColunas: shouldShowColunas,
    BadgeBomKm: auction?.vehicle?.kilometrageAcceptable && flagBadgeKm,
    BadgeCambio: auction?.vehicle?.canHaveTransmissionBadge && flagBadgeCambio,
    BadgeScanner: auction?.vehicle?.canHaveScannerBadge && flagBadgeScanner,
    BadgeMargem: auction?.vehicle?.isBelowThreshold && flagBadgeMargem,
    BadgeRevenda: auction?.vehicle?.fastSpin && flagBadgeRevenda,
    BadgeUberSP: shouldShowBadgeUberSP,
    BadgeUberRJ: shouldShowBadgeUberRJ,
    BadgeUberBoothStates: shouldShowBadgeUberBoothStates,
  };

  const BADGES = [
    {
      icone: Cautelar,
      titulo: 'Cautelar sem apontamentos',
      descricao: 'Cautelar verificada e aprovada',
    },
    {
      icone: Longarina,
      titulo: 'Longarina sem apontamentos',
      descricao: 'Verificada e sem observações',
    },
    {
      icone: Colunas,
      titulo: 'Colunas sem apontamentos',
      descricao: 'Verificadas e sem observações',
    },
    {
      icone: Km,
      titulo: 'Boa km por ano',
      descricao: 'Na média de km para carros do mesmo ano',
    },
    {
      icone: Cambio,
      titulo: 'Câmbio sem apontamentos',
      descricao: 'Teste inerte sem ressalvas',
    },
    {
      icone: Scanner,
      titulo: 'Scanner sem apontamentos',
      descricao: 'Verificado e sem observações',
    },
    {
      icone: Margem,
      titulo: 'Margem alta na revenda',
      descricao: '70% abaixo do preço da web',
    },
    {
      icone: Giro,
      titulo: 'Giro rápido',
      descricao: 'Mais vendidos segundo a Fenabrave',
    },
    {
      icone: UberSP,
      titulo: 'Aceito no Uber Black SP e outros',
      descricao:
        'Modelo autorizado para Uber Black em São Paulo, Brasília, Curitiba, Belo Horizonte e Porto Alegre',
    },
    {
      icone: UberRJ,
      titulo: 'Aceito no Uber Black RJ e outros',
      descricao:
        'Modelo autorizado para Uber Black em Rio de Janeiro, Campinas, Salvador, Recife, Florianópolis, Goiânia, Fortaleza e Vitória',
    },
    {
      icone: Uber,
      titulo: 'Aceito no Uber Black SP, RJ e outros',
      descricao:
        'Modelo autorizado para Uber Black em São Paulo, Brasília, Curitiba, Belo Horizonte, Porto Alegre, Rio de Janeiro, Salvador, Recife, Florianópolis, Goiânia, Fortaleza e Vitória',
    },
  ];

  const trueBadgesKeys = Object.keys(BADGE_STATUS).filter(key => BADGE_STATUS[key]);

  const getTrueBadges = () => {
    return trueBadgesKeys.reduce((result, key) => {
      const badgeIndex = Object.keys(BADGE_STATUS).indexOf(key);
      if (badgeIndex !== -1 && BADGES[badgeIndex]) {
        result[key] = BADGES[badgeIndex];
      }
      return result;
    }, {});
  };

  const badgesToDisplay = getTrueBadges();

  const getVersionByFuel = useCallback(() => {
    let matched;

    if (!summary?.version) {
      return '-';
    }

    fuel.forEach(f => {
      if (
        summary.version
          .normalize('NFD')
          .replace(/\p{Mn}/gu, '')
          .toUpperCase()
          .includes(f)
      ) {
        matched = f.charAt(0).toUpperCase() + f.slice(1).toLowerCase();

        if (matched === 'Eletrico') {
          matched = 'Elétrico';
        }

        if (matched === 'Hibrido' || matched === 'Hybrid') {
          matched = 'Híbrido';
        }

        if (matched === 'Hibrido flex' || matched === 'Hybrid flex') {
          matched = 'Híbrido/Flex';
        }

        if (matched === 'Jetflex gasolina') {
          matched = 'Gasolina';
        }
      }
    });

    if (!summary?.summary) {
      return matched || '-';
    }

    if (summary.summary.toUpperCase().includes('GNV')) {
      return `${matched}/GNV`;
    }

    return matched || '-';
  }, [summary]);

  const getGear = useMemo(() => {
    if (summary?.version?.includes('MANUAL')) {
      return 'Manual';
    }

    return summary?.version?.includes('AUTOMATIZADO') ? 'Automatizado' : 'Automático';
  }, [summary]);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Container enabled={shouldShowFipeWebPrices}>
      <VehicleArea>
        {shouldShowFipeWebPrices && (
          <ReferencePrices marketPrice={marketPrice} webPrice={summary?.webPrice} />
        )}

        {trueBadgesKeys.length > 0 && (
          <Column>
            <BadgeAreaTitle>Destaques da oferta</BadgeAreaTitle>
            <BadgeArea>
              {Object.entries(badgesToDisplay).map(([key, badge]) => (
                <Column key={key}>
                  <Badge>
                    <BadgeIcon src={badge.icone} />
                    <BadgeContent>
                      <BadgeTitle>{badge.titulo}</BadgeTitle>
                      <BadgeDescription>{badge.descricao}</BadgeDescription>
                    </BadgeContent>
                  </Badge>
                  <Divider />
                </Column>
              ))}
            </BadgeArea>
          </Column>
        )}

        <ScoreLocation>
          <SpanRow>
            <TiLocation
              size={27}
              color="#80BEB0"
              style={{ verticalAlign: 'text-top', marginRight: '0.3em' }}
            />
            Retirada em {summary?.vehicleYardRegion ?? summary?.state}*
          </SpanRow>
        </ScoreLocation>

        <Row>
          <Column>
            <List>
              <ListItem>
                <span>Ano</span>
                <strong>{summary?.year}</strong>
              </ListItem>
              <ListItem>
                <span>Km</span>
                <strong>
                  {summary?.km?.toLocaleString('pt-BR', { minimumFractionDigits: 0 })}
                </strong>
              </ListItem>
              <ListItem>
                <span>Câmbio</span>
                <strong>{getGear}</strong>
              </ListItem>
              <ListItem>
                <span>Combustível</span>
                <strong>{getVersionByFuel()}</strong>
              </ListItem>
              <ListItem>
                <span>Cautelar</span>
                <strong>{injuctionNote}</strong>
              </ListItem>
            </List>
          </Column>

          <Column>
            <List>
              <ListItem>
                <span>Final da Placa</span>
                <strong>{String(summary?.plateNumber).substring(7)}</strong>
              </ListItem>
              <ListItem>
                <span>Emplacado em</span>
                <strong>{summary?.plateLocation}</strong>
              </ListItem>
              <ListItem>
                <span>Conjunto de chaves</span>
                <strong>{keySet}</strong>
              </ListItem>
              <ListItem>
                <span>Manual ou Livreto</span>
                <strong>{manualBooklet}</strong>
              </ListItem>
            </List>
          </Column>
        </Row>
      </VehicleArea>
      <DutLabel>
        {updatedAuction?.summary?.documentResearch?.dutLabel ||
          updatedAuction?.documentResearch?.dutLabel}
      </DutLabel>
      {updatedAuction?.documentResearch && (
        <DocumentResearch showHistoric research={updatedAuction?.documentResearch} />
      )}
    </Container>
  );
};
