import React from 'react';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';

import { Tag } from '../../../../components/NewTag';

import {
  Container,
  VehicleArea,
  BadgeArea,
  BadgeAreaTitle,
  Row,
  Column,
  List,
  ListItem,
  HeaderContainer,
} from './styles';

export const VehicleConditionsD2D = props => {
  const { updatedAuction } = props;

  return (
    <Container>
      <VehicleArea>
        <HeaderContainer>
          <BadgeAreaTitle>
            <TimeToLeaveIcon />
            Condições do Veículo
          </BadgeAreaTitle>
          <Tag.Container
            bg="#171C24"
            style={{
              display: 'inline-block',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Tag.Text color="#FFF6A7" style={{ fontSize: '0.75rem', textAlign: 'center' }}>
              Avaliação realizada pelo parceiro vendedor{' '}
            </Tag.Text>
          </Tag.Container>
          <div>Confira as condições do veículo, o Laudo Cautelar e Pesquisa Veicular.</div>
        </HeaderContainer>

        <Row>
          <Column>
            <List>
              <ListItem>
                <span>Estrutura</span>
                <strong>{updatedAuction?.summary?.structure}</strong>
              </ListItem>
              <ListItem>
                <span>Pintura</span>
                <strong>{updatedAuction?.summary?.additionalInfo?.painting}</strong>
              </ListItem>
              <ListItem>
                <span>Direção</span>
                <strong>{updatedAuction?.summary?.additionalInfo?.carSteering}</strong>
              </ListItem>
            </List>
          </Column>

          <Column>
            <ListItem>
              <span>Manual</span>
              <strong>{updatedAuction?.summary?.additionalInfo?.hasManual}</strong>
            </ListItem>
            <ListItem>
              <span>Carro liga?</span>
              <strong>{updatedAuction?.summary?.additionalInfo?.carStarts}</strong>
            </ListItem>
          </Column>

          <Column>
            <ListItem>
              <span>Revisões carimbadas</span>
              <strong>{updatedAuction?.summary?.additionalInfo?.stampedReviews}</strong>
            </ListItem>
            <ListItem>
              <span>Chave reserva</span>
              <strong>{updatedAuction?.summary?.additionalInfo?.hasKeySet}</strong>
            </ListItem>
          </Column>
        </Row>
      </VehicleArea>
    </Container>
  );
};
