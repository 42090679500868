import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  min-height: 238px;

  @media screen and (max-width: 1025px) {
    height: auto;
  }
  border: 0.063rem solid #d2d4d7;
  border-radius: 0.5rem;

  box-shadow: 0 0.25rem 0.25rem rgba(34, 116, 165, 0.1);

  background: ${({ isPartnerEvaluated }) => (isPartnerEvaluated ? '#CDF4B9' : '#fff')};

  @media screen and (max-width: 1025px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;

  padding: 1.125rem 0px;

  @media (max-width: 760px) {
    padding: 1.125rem 10px;
  }

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const VehicleInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 325px;
  margin-right: 1.313rem;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
`;

export const VehicleNameAndModel = styled(Link)`
  display: flex;
  align-items: center;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FavoriteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  background-color: transparent;
  border: none;

  outline: none;
  cursor: pointer;
`;

export const VehicleDescription = styled.span`
  margin-bottom: 0.8rem;

  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #757778;
`;

export const VehicleApprovalAndLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
`;

export const VehicleApprovalContainer = styled.div`
  display: flex;
  align-items: center;

  margin-right: 1.125rem;

  @media screen and (max-width: 1025px) {
    margin-right: 0;
  }
`;

export const VehicleApprovalLabel = styled(Link)`
  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #5d5d5e;
  text-decoration: none;

  cursor: pointer;
`;

export const VehicleLocationContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const VehicleLocationLabel = styled.span`
  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: ${({ isPartnerEvaluated }) => (isPartnerEvaluated ? '#05762D' : '#1ab43f')};
`;

export const ShippingPopover = styled.span`
  position: absolute;
  top: 30px;
  right: 0;
  left: unset;

  min-width: 15rem;
  padding: 0.5rem;

  font: 700 normal 0.75rem/1.3em 'Roboto', sans-serif;
  color: #5d5d5e;

  background: white;

  border-top: 2px solid rgb(61 184 112);
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 0px;

  z-index: 99;

  &:before {
    content: '';

    position: absolute;
    bottom: 100%;
    left: 16.5em;

    width: 0;
    height: 0;

    border: 0.5rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(61 184 112);

    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`;

export const VehicleTemperatureContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.938rem;
`;

export const VehicleRestrictions = styled.span`
  font: 400 normal 0.875rem/1.375em 'Roboto', sans-serif;
  color: #757778;

  @media screen and (max-width: 1025px) {
    padding: 1.125rem;
  }
`;

export const Divider = styled.div`
  width: 1px;
  margin-right: 1.25rem;

  border: 0.063rem solid #d6d6d6;

  @media screen and (max-width: 1025px) {
    display: none;
  }
`;

export const VehicleChronometerAndLastOffer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 350px;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const VehicleLastOfferLabel = styled.span`
  margin-bottom: 0.25rem;

  font: 400 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const VehicleLastOfferAndIsWinningStatus = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
`;

export const VehicleLastOfferValue = styled.span`
  margin-right: 0.438rem;

  font: 700 normal 1.125rem/1em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const VehicleIsWinningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;

  padding: 0.25rem 0.5rem;

  background-color: ${props => props.bg};
  border-radius: 1.25rem;
`;

export const VehicleIsWinningLabel = styled.span`
  font: 700 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #fff;
`;

export const VehicleAutoBidContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:before {
    content: '';

    display: inline-block;

    width: 100%;
    height: 1px;

    margin: 0.75rem 0;

    background-color: #d6d6d6;
  }
`;

export const VehicleAutoBidMessageContainer = styled.div`
  display: flex;
  margin-bottom: 0.3rem;
`;

export const AlertIconImg = styled.img`
  align-self: flex-start;

  margin-top: 0.2rem;
  margin-right: 0.3rem;
`;

export const VehicleAutoBidMessageLabel = styled.span`
  font: 400 normal 0.875rem/1.5em 'Roboto', sans-serif;
  color: #2f3741;
`;

export const VehicleAutoBidRules = styled.button`
  padding: 0;

  font: 400 normal 0.875rem/1.4em 'Roboto', sans-serif;
  color: #2f3741;
  text-decoration: underline;

  background-color: transparent;
  border: none;

  cursor: pointer;
  outline: none;
`;

export const VehicleBidContainer = styled.div`
  width: 40%;
  background-color: #f3f3f3;
  border: 0.063rem solid #e4e4e4;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 1.375rem 1.25rem;

  @media screen and (max-width: 1025px) {
    width: 100%;
    padding: 1.375rem 1.25rem;
    border-radius: 0;
  }
`;

export const BidActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 1rem 0;

  background-color: transparent;

  @media screen and (max-width: 1025px) {
    padding: 0;
  }
`;

export const InputContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 0.75rem 0;
  margin-bottom: 0.75rem;

  background-color: #fff;

  border: 0.063rem solid #d2d4d7;
  border-radius: 0.25rem;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const InputLabel = styled.span`
  position: absolute;
  top: -6px;
  left: 14px;

  font: 400 normal 0.75rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  background-color: #fff;
`;

export const InputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 50%;
  margin: 0 1.2rem;
`;

export const InputStyled = styled.input`
  width: 70%;
  padding: 0.3rem 0;

  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;

  border: none;
  outline: none;
`;

export const QuickKeyboardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: ${({ submitButtonIsVisible }) => (submitButtonIsVisible ? '0.75rem' : '1.275rem')};

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const QuickKeyboardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;

  width: 87px;
  height: 40px;

  font: 700 normal 1rem/1em 'Roboto', sans-serif;
  color: #fff;

  background-color: #2274a5;

  border: none;
  border-radius: 0.5rem;

  cursor: pointer;
  outline: none;

  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #4b8cb8;
    transition: background-color 0.2s ease-in;
  }

  /* @media screen and (max-width: 1025px) {
    &:nth-child(2) {
      margin: 0 1.5rem;
    }
  } */
`;

export const VehicleLastOfferResponsive = styled.div`
  display: flex;
  flex-direction: column;

  ${({ displayWidth }) =>
    displayWidth !== 'lg' &&
    css`
      margin: 1.25rem 0;
    `}
`;

export const ErrorMessage = styled.span`
  width: 85%;
  margin-bottom: 0.75rem;

  font: 500 normal 0.9rem/1em 'Roboto', sans-serif;
  color: #ff4c00;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const AutoBidViewNinja = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8rem 2rem;
`;

export const AutoBidViewNinjaLabel = styled.span`
  font: 400 normal 1rem/1.4em 'Roboto', sans-serif;
  color: #757778;
`;

export const MoreOptionsButton = styled.button`
  display: flex;
  align-items: center;

  padding: 0;
  /* height: 100%; */
  /* margin-top: 0.625rem; */

  font: 700 normal 0.875rem/1em 'Roboto', sans-serif;
  color: #2f3741;
  text-transform: uppercase;

  background-color: transparent;
  border: none;

  outline: none;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 0) and (max-width: 1024px) {
    gap: 16px 0;
    flex-direction: column;
    width: 100%;
  }

  @media screen and (min-width: 1025px) {
    gap: 0 16px;
  }
`;

export const BuyForButton = styled.button`
  width: 100%;
  padding: 12px 0;

  font: 400 normal 16px/1em 'Roboto', sans-serif;
  color: #000;
  text-align: center;

  background-color: #f2c94c;

  border: none;
  border-radius: 4px;

  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px 0;

  padding: 0 40px;

  width: 100%;
  height: 100%;

  background-color: transparent;

  ${props =>
    props.isNinja &&
    css`
      gap: 16px 0;
    `}

  @media screen and (max-width: 1025px) {
    padding: 0;
    gap: 20px 0;
  }
`;

export const MoreOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;
  width: 100%;
`;

export const MoreOptionsContent = styled(MoreOptionsWrapper)`
  gap: 12px 0;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 16px;
`;

export const PartnerEvaluatedTag = styled.span`
  z-index: 2;
  position: absolute;
  top: 15px;
  left: 14px;
  color: #fff6a7;
  background-color: #000;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;
