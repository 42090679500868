import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FaHeart, FaMapMarkerAlt, FaRegHeart } from 'react-icons/fa';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { GoVerified } from 'react-icons/go';

import { Presentation, CountdownBox, Container, StatusTag, FipeTag } from './styles';
import { useAuctionTemperature } from '../../../../hooks/useAuctionTemperature';
// import { useUserFavoriteVehicle } from '../../../../context/FavoriteContext';
import { fractionToPercentage } from '../../../../utils/number';
import { AuctionItemTimer } from '../../../enhanced';
import { Box, CarouselPhoto } from '../../..';
import Modal from '../../../enhanced/Modal';
import { Dialog } from '../../../dialog';
import Temperature from '../Temperature';
import MakeOffer from '../MakeOffer';
import { TimingAuctionContextProvider } from '../../../../context/TimingAuctionContext';
import { useFlag } from '@unleash/proxy-client-react';

function Card({
  showFipePercentage = false,
  verticalStyle = false,
  handleOnLoad = null,
  isHighlight = false,
  showStatus = false,
  currentTab = 0,
  auction,
}) {
  const [openStateTooltip, setOpenStateTooltip] = useState(false);
  // const { isFavorite, addFavorite, removeFavorite } = useUserFavoriteVehicle(auction._id);
  const hasFinished = auction.auction.status === 'FINISHED';
  const [fipePercentage, setFipePercentage] = useState();
  const [restrictions, setRestrictions] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();

  const lastOffer = useMemo(() => auction.auction.winningBid.amount || 0, [
    auction.auction.winningBid.amount,
  ]);
  const { hasValidTemperature, statusLabel } = useAuctionTemperature(auction, lastOffer);

  const enableBidValue = useFlag('change-bid-value-SUI-1054');

  const calculateFipePercentage = useCallback(() => {
    if (!auction.summary.marketPrice) return false;
    return Math.trunc((100 * lastOffer) / auction.summary.marketPrice);
  }, [auction, lastOffer]);

  /**
   * Get auction restrictions if any
   *
   */
  const getHistoric = useCallback(
    function() {
      if (
        auction.summary.documentResearch.historics &&
        auction.summary.documentResearch.historics.length > 0
      ) {
        return auction.summary.documentResearch.historics;
      }
      return [];
    },
    [auction.summary.documentResearch]
  );

  /**
   * Initialize the component
   *
   */
  useEffect(() => {
    const historic = getHistoric();
    const fipePercent = calculateFipePercentage();
    const historicList = historic.join(', ');

    setRestrictions(historicList);
    setFipePercentage(fipePercent);
  }, [getHistoric, calculateFipePercentage]);

  useEffect(
    function() {
      if (handleOnLoad) {
        handleOnLoad(auction._id);
      }
    },
    [handleOnLoad, auction]
  );

  /**
   * Returns if the user is winning the auction or not
   *
   */
  function isWinning() {
    return auction.auction.winning ?? false;
  }

  /**
   * Redirect user to auction details
   *
   */
  function goToDetailsPage() {
    history.push(`/detalhes/${auction._id}`);
  }

  /**
   * Handle tooltip outside click
   *
   */
  const tooltipOutSideClickHandler = () => {
    if (openStateTooltip) setOpenStateTooltip(false);
  };

  useEffect(() => {
    window.addEventListener('click', tooltipOutSideClickHandler, false);
    return () => {
      window.removeEventListener('click', tooltipOutSideClickHandler, false);
    };
  }, [openStateTooltip]);

  // function setFavoriteAction() {
  //   if (isFavorite) {
  //     setShowDialog(true);
  //   } else {
  //     addFavorite();
  //   }
  // }

  // function removeAuctionFromFavorites() {
  //   removeFavorite();
  //   setShowDialog(false);
  // }

  function getContainerClasses() {
    let classes = [];
    if (verticalStyle) classes.push('vertical-style');
    if (hasFinished) classes.push('disabled');
    return classes.join(' ');
  }

  const getMessageUF = useMemo(() => {
    if (auction?.summary?.state === 'SP') {
      return 'O carro poderá ser retirado em nosso pátio em SP.';
    }

    return auction?.summary?.state === 'RJ'
      ? 'O carro poderá ser retirado em nossa base no RJ ou em nosso pátio em SP, mediante programação e frete por conta do lojista.'
      : 'O carro poderá ser retirado em nossa base local ou em nosso pátio em SP, mediante programação e frete por conta da InstaCarro.';
  }, [auction?.summary?.state]);

  return (
    <TimingAuctionContextProvider auction={auction.auction}>
      <Container id={auction._id} isHighlight={isHighlight} className={getContainerClasses()}>
        {showStatus && auction.auction.participating && isWinning() && (
          <StatusTag className="desktop-version mobile-version winning">
            <span>Ganhando</span> <MdAttachMoney />
          </StatusTag>
        )}
        {showStatus && auction.auction.participating && !isWinning() && (
          <StatusTag className="desktop-version mobile-version losing">
            <span>Perdendo</span> <MdMoneyOff />
          </StatusTag>
        )}
        {showFipePercentage && fipePercentage && <FipeTag>{fipePercentage}% da Fipe</FipeTag>}

        <div className={`left ${verticalStyle ? 'vertical-style' : ''}`}>
          <div className={`image ${verticalStyle ? 'vertical-style' : ''}`}>
            <div className="image-container">
              <CarouselPhoto
                photos={auction.summary.photos}
                arrows
                dots
                counter
                onClickSlide={() => {
                  history.push(`/detalhes/${auction._id}`);
                }}
              ></CarouselPhoto>
            </div>
          </div>
          <div className={`description ${verticalStyle ? 'vertical-style' : ''}`}>
            <Presentation.Wrapper>
              <Presentation.Title href={`/detalhes/${auction._id}`}>
                {auction.summary.make} {auction.summary.model}
              </Presentation.Title>
              {/* <Presentation.FavoriteBtn onClick={setFavoriteAction}>
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </Presentation.FavoriteBtn> */}
            </Presentation.Wrapper>

            <Presentation.Description>
              {auction.summary.yearLabel} - {auction.summary.version} {auction.summary.kmLabel}
            </Presentation.Description>
            {restrictions && (
              <Presentation.Description
                className={`hide-when-mobile small ${verticalStyle ? 'vertical-style' : ''}`}
              >
                Restrições: {restrictions}
              </Presentation.Description>
            )}
            <div className={`extras ${verticalStyle ? 'vertical-style' : ''}`}>
              <Presentation.Percentage onClick={goToDetailsPage}>
                <GoVerified />
                <span>
                  Aprovado em {Math.round(auction?.summary?.goodPointsCount || 0)}% dos pontos.
                </span>
              </Presentation.Percentage>
              <Presentation.StateTag
                className={verticalStyle ? 'vertical-style' : ''}
                onClick={() => setOpenStateTooltip(!openStateTooltip)}
              >
                <FaMapMarkerAlt />
                <span>{auction.summary.state}</span>
                {openStateTooltip && <span className="tooltip">{getMessageUF}</span>}
              </Presentation.StateTag>
            </div>
          </div>
        </div>
        <div className={`right ${verticalStyle ? 'vertical-style' : ''}`}>
          <div className={`bid-value ${verticalStyle ? 'vertical-style' : ''}`}>
            <MakeOffer
              lastOffer={lastOffer}
              showButton={currentTab === 0}
              hasFinished={hasFinished}
              auction={auction}
              bidValue={enableBidValue ? 500 : 250}
            />
          </div>
          {!hasFinished && (
            <div className={`countdown ${verticalStyle ? 'vertical-style' : ''}`}>
              <CountdownBox className={verticalStyle ? 'vertical-style' : ''}>
                <AuctionItemTimer showBuyNowButton={false} auction={auction} />
              </CountdownBox>
            </div>
          )}
        </div>
        {restrictions && (
          <div
            className={`mobile-footer hide-when-desktop ${verticalStyle ? 'vertical-style' : ''}`}
          >
            <div className="divider"></div>
            <Presentation.Description className="small">
              <span className="bold">Restrições:</span> {restrictions}
            </Presentation.Description>
          </div>
        )}
        <Modal show={showDialog}>
          <Dialog
            header="Deseja remover esse veículo dos seus favoritos?"
            // onCTAClick={removeAuctionFromFavorites}
            onSecondaryBtnClick={() => setShowDialog(false)}
            primaryBtnLabel="Sim, remover"
            primaryBtnVariant="warning"
            secondaryBtnLabel="Não remover"
            secondaryBtnVariant="ghost.warning"
          ></Dialog>
        </Modal>
      </Container>
    </TimingAuctionContextProvider>
  );
}

export default Card;
