import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #757778;
  cursor: pointer;
  padding: 0;
  margin-right: auto;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  display: none;
`;

const Title = styled.h2`
  font-family: Rubik, sans-serif;
  font-size: 1rem;
  font-weight: 800;
  color: #2f3741;
  margin-top: 20px;
`;

const Subtitle = styled.p`
  font-family: Rubik, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #2f3741;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #d2d4d7;

  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 16px 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  vertical-align: top;

  &:first-child {
    font-weight: 600;
    width: 30%;
  }
`;

const ImportantNote = styled.div`
  font-family: Rubik, sans-serif;
  font-size: 16px;
  margin-bottom: 20px;

  span {
    color: #ff4c00;
    font-weight: 600;
  }
`;

const PolicyLink = styled.a`
  display: block;
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  color: #032470;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
`;

export const ReturnPolicyModal = ({ show, onClose }) => {
  return (
    <ModalOverlay show={show} onClick={onClose}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <BackButton onClick={onClose}>
            <ChevronLeftIcon />
            Voltar
          </BackButton>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>

        <Title>A garantia é de responsabilidade do vendedor parceiro.</Title>
        <Subtitle>
          Em caso de divergências, a InstaCarro intermediará o processo de garantia. Certifique-se
          de que está de acordo com a política de devolução:
        </Subtitle>

        <Table>
          <tbody>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Garantia do parceiro</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Avaliação</TableCell>
              <TableCell>
                Os itens informados na avaliação são de responsabilidade do parceiro. Itens não
                avaliados não estão cobertos.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Laudo cautelar</TableCell>
              <TableCell>
                O vendedor deve garantir que o carro esteja nas mesmas condições descritas no laudo
                apresentado, incluindo a presença de todas as peças.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mecânica</TableCell>
              <TableCell>
                O vídeo do motor e câmbio tem caráter informativo. O veículo será entregue nas
                condições apresentadas no vídeo.
              </TableCell>
            </TableRow>
          </tbody>
        </Table>

        <ImportantNote>
          <span>Importante:</span> Para contestação do resultado de laudo cautelar, serão aceitos
          somente laudos da mesma empresa emissora para fins de comparação.
        </ImportantNote>

        <PolicyLink href="#">Clique aqui e veja a Política de devolução completa.</PolicyLink>
      </ModalContainer>
    </ModalOverlay>
  );
};
