import React from 'react';

import ScrollView from '../Components/Carousel/ScrollView';
import Slide from '../Components/Carousel/Slide';

import AuctionNewOffersCard from '../../AuctionCards/AuctionNewOffersCard';

function HighlightOffersList({ auctions }) {
  return (
    <ScrollView slidesNumber={auctions.length}>
      {auctions?.map((auction, key) => (
        <Slide key={key}>
          <AuctionNewOffersCard auction={auction} isHighlight={true} />
        </Slide>
      ))}
    </ScrollView>
  );
}

export default HighlightOffersList;
