import styled from 'styled-components';

export const Row = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #032470;
  display: flex;
  gap: 6px;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 16px;
  border-top: 1px solid #eaebec;
  padding-top: 16px;
  align-items: center;
`;

export const AreaTitle = styled.span`
  font-family: Rubik;
  color: #032470;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.64px;
`;
