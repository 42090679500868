import React, { useState } from 'react';
import { Tag } from '../../../../components/NewTag';

import {
  Container,
  VehicleArea,
  BadgeArea,
  BadgeAreaTitle,
  Row,
  Column,
  List,
  ListItem,
  HeaderContainer,
  BadgeAreaSubtitle,
} from './styles';
import { ReturnPolicyModal } from './ReturnPolicyModal';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export const ReturnPolicyD2D = props => {
  const { updatedAuction } = props;
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Container onClick={openModal} style={{ cursor: 'pointer' }}>
        <VehicleArea>
          <HeaderContainer>
            <BadgeAreaTitle>
              <ArrowCircleRightIcon />
              Política de devolução
            </BadgeAreaTitle>
            <BadgeAreaSubtitle>
              O vendedor deste veículo é o responsável pela avaliação, por isso, atente-se a
              Política de devolução do parceiro. <a href="#">Saiba mais.</a>
            </BadgeAreaSubtitle>
          </HeaderContainer>
        </VehicleArea>
      </Container>

      <ReturnPolicyModal show={showModal} onClose={closeModal} />
    </>
  );
};
