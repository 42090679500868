import React from 'react';
import PropTypes from 'prop-types';
import { MdError } from 'react-icons/md';
import { rem } from 'polished';
import { Text, Box, Flex } from '../../../../components';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Row, AreaTitle } from './styles';
export const DocumentResearch = ({
  title,
  research,
  showObservations = false,
  showHistoric = false,
  ...etc
}) => {
  const { historics, dutLabel, observations } = research;
  const hasHistorics = showHistoric && historics && historics.length;

  return (
    <Box px={32} width="100%" {...etc}>
      <Box width="100%">
        {showObservations ? (
          <Flex flexWrap="wrap" justifyContent="space-between" flexDirection="column">
            <Text
              fontFamily="Roboto"
              color="#2F3741"
              fontSize={3}
              fontWeight="400"
              lineHeight="27px"
              mb={4}
            >
              summary
            </Text>
            {dutLabel && (
              <Text color="neutrals.EclipseE800" fontSize={2}>
                <b>Observação: </b> {dutLabel}.
              </Text>
            )}

            {observations && (
              <Text width={{ md: 1 / 3 }} fontSize={1} color="#222B37" mb={{ sm: 1, md: 0 }}>
                {observations}
              </Text>
            )}
            <Text color="neutrals.EclipseE800" fontSize={2}>
              *Consulte disponibilidade de frete para demais regiões.
            </Text>
          </Flex>
        ) : null}

        {hasHistorics && (
          <Row>
            <WatchLaterIcon />
            <AreaTitle>{title}</AreaTitle>
          </Row>
        )}
      </Box>
      <Box width={1}>
        {hasHistorics &&
          historics.map(el => (
            <Text
              fontSize={1}
              fontWeight="paragraphs.medium"
              key={el}
              width={{ md: 1, lg: 3 / 12 }}
            >
              <Flex>
                <Box fontSize={4} mr={1} color="alerts.Ferrari">
                  <MdError />
                </Box>
                {el}
              </Flex>
            </Text>
          ))}
      </Box>
    </Box>
  );
};

DocumentResearch.propTypes = {
  title: PropTypes.string,
  research: PropTypes.object.isRequired,
};
DocumentResearch.defaultProps = {
  title: 'Histórico',
};
