import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({ enabled }) => (!enabled ? '24px' : 0)};

  @media screen and (max-width: 1025px) {
    align-items: flex-start;
  }
`;

export const VehicleArea = styled.div`
  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0 100px;
  max-width: 1200px;
  width: 100%;
  padding: 0 32px;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1025px) {
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 50%;
    }
  }
`;

export const BadgeAreaTitle = styled.div`
  font-family: Rubik;
  font-size: 1.1rem;
  font-weight: 700;
  color: #063856;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 320px;
  margin-top: 2rem;
  margin-bottom: 6px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 0.8rem;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: 200;
  font-size: 1rem;
  color: #757778;
`;

export const CautelarContainer = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CautelarButton = styled.button`
  background-color: #ff6c1c;
  color: #ffffff !important;
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 4px #e05a1030;
  border: 0;
  &:hover {
    background-color: #e05a10;
  }
`;

export const PesquisaLink = styled.a`
  color: #007bff;
  text-decoration: underline;
  margin-top: 15px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #0056b3;
  }
`;
