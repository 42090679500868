/* eslint react/no-multi-comp: 0 */
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FaHeart } from 'react-icons/fa';
import {
  MdGavel,
  MdAttachMoney,
  MdDriveEta,
  MdVpnKey,
  MdForum,
  MdSupervisorAccount,
} from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import NewIcon from '../../images/icons/new_icon.svg';

import { Box, Flex } from '../abstract';
import { SideMenu } from '../side-menu';
import logo from '../../images/logo.svg';
import { Button } from '../Buttons/old';
import AppConstants from '../../constants/appConstants';
import UserInfo from './UserInfo';
import { deauthenticate } from '../../store/actions/authentication.actions';
import { getAuthenticatedUser, getUser } from '../../store/selectors/userSelectors';
import { forgotPassword } from '../../utils/fecth-wrappers';
import { Badge } from '../StyledBadge';
import PersonIcon from '@mui/icons-material/Person';
import { showModal } from '../../store/actions/ui.actions';
import { PREFERENCES_CONFIG_KEY } from '../Modals';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { RecommendedIcon } from '../Icons';
import FinanIcon from '../../images/icons/finan-icon.svg';
import Cookies from 'js-cookie';
import { checkFinancingEnabled, loadCreditAvaible } from '../../services/fincredit';
import { useSegment } from '../../hooks/useSegment';
import { TagFincreditLimit } from '../../components/TagFincreditLimit';
import VirtualShowcaseIcon from '../../images/icons/vitrine-virtual.svg';
import AdvertisingManagementIcon from '../../images/icons/gestao-de-anuncios.svg';

const arr = ['Pagamento Facilitado', 'Vitrine virtual', 'Gestão de anúncios'];

const NavButton = styled(Flex)`
  border-bottom: 2px solid;
  cursor: pointer;
  border-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 0 0.5em;
  ${({ active }) =>
    active &&
    `
  color:#2274A5;
  border-color: currentColor;
  `}
`;
const SideMenuLink = styled(NavButton)`
  min-height: 3rem;
  justify-content: flex-start;
  gap: 0 8px;
  font-size: 1rem;
`;

const Divider = styled(Box)`
  width: 1px;
  height: 40%;
  background: #e6e6e6;
  margin: auto;
`;

const tabs = [
  {
    href: '/ferramenta-comercial',
    label: 'Comercial',
    onlyNinja: true,
  },
  {
    href: '/',
    label: 'Disputas',
  },
  {
    href: '/favoritos',
    label: 'Favoritos',
    hideMd: true,
  },
  {
    href: '/compras',
    label: 'Minhas compras',
  },
  {
    href: '/perfis',
    label: 'Perfis',
    hideMd: true,
  },
  {
    href: 'https://seminovos.instacarro.com/',
    label: 'Vitrine virtual',
    hideMd: true,
  },
  {
    href: 'https://ec2c-backoffice.instacarro.com/admin/login',
    label: 'Gestão de anúncios',
    hideMd: true,
  },
  {
    href: '/pagamento-facilitado/simule',
    label: 'Pagamento Facilitado',
    hideMd: true,
  },
];

const cookies = ['__hssc', '__hssrc', '__hstc', 'hs-messages-is-open', 'hubspotutk', 'messagesUtk'];

export const TopNav = () => {
  const sideMenuRef = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const updateContext = useUnleashContext();

  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);
  const [fincreditInfo, setFincreditInfo] = useState();

  const enabledOrderCars = useFlag('order-cars');
  const enabledVirtualShowcase = useFlag('vitrine-virtual');
  const enabledAdvertisingManagement = useFlag('gestao-de-anuncios');
  const enabledNewTag = useFlag('tag-novo');
  const enabledSimulationBanner = useFlag('banner-simulacao');

  const { trackFinCredit } = useSegment();

  const { user } = useSelector(getAuthenticatedUser);
  const { isRegistrationOutdate: isUserRegistrationOutdate } = useSelector(getUser);
  const { loading: preferencesLoaded, error: preferencesError } = useSelector(
    state => state.orderCars
  );
  const { id: preferenceId } = useSelector(state => state.orderCars.userPreferences);

  const loggedIn = user && user.isLoggedIn && user.isLoggedIn();
  const showPreferenceButton = !!preferenceId && !preferencesLoaded && !preferencesError;

  const handleSideMenuLinkClick = () => {
    if (sideMenuRef) {
      sideMenuRef.current.close();
    }
  };

  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLoadData = async () => {
    try {
      const response = await loadCreditAvaible(user?.id, user?.email, 0);
      setFincreditInfo(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
      fetchLoadData();
    }
  }, [enabledSimulationBanner]);

  const domain = AppConstants.DOMAIN_APP;

  const cookiesRemove = name => Cookies.remove(name, { path: '', domain });

  const handleLogout = async () => {
    sideMenuRef.current.close();

    await dispatch(
      deauthenticate(() => {
        history.push('/login');
      })
    );

    await analytics.reset();
    await localStorage.removeItem('user-identified');
    await cookies.forEach(item => cookiesRemove(item));
    await window.HubSpotConversations.clear({ resetWidget: true });
  };

  const handleChangePassword = () => {
    forgotPassword(user.email);
    handleLogout();
    sideMenuRef.current.close();
  };

  const copy = user.isNinja()
    ? 'Recomendados para você'
    : showPreferenceButton
    ? 'Alterar preferências de ofertas'
    : 'Definir preferências de ofertas';

  const [size, setSize] = useState(window.screen.width);

  window.addEventListener('resize', () => setSize(window.screen.width));

  return (
    <Flex
      width={1}
      as="header"
      minHeight="3.7rem"
      css={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)', zIndex: 4 }}
      fontSize={{ sm: 1, md: 2 }}
      color="neutrals.EclipseE500"
      bg="white"
    >
      <Flex
        width={1}
        m="0 auto"
        className="container"
        justifyContent="space-between"
        alignItems="center"
        px={1}
      >
        <Link to="/">
          <Box as="img" src={logo} alt="Instacarro Logo" width={{ sm: '100px', md: '150px' }} />
        </Link>

        <Flex height="100%" as="nav" color="navyBlue700">
          {loggedIn &&
            tabs
              .filter(i => (i.onlyNinja ? user.isNinja() : true))
              .map(i =>
                i.label === 'Pagamento Facilitado' && displaySimulationBanner && size >= 1024 ? (
                  <Fragment>
                    <NavButton as="a" href={i.href}>
                      {i.label}
                      {fincreditInfo?.creditAvailableAmount > 0 && displaySimulationBanner && (
                        <div style={{ paddingLeft: '5px' }}>
                          <TagFincreditLimit limitValue={fincreditInfo?.creditAvailableAmount} />
                        </div>
                      )}
                    </NavButton>
                    <Divider />
                  </Fragment>
                ) : !arr.includes(i.label) ? (
                  <Fragment key={`tabs-${i.href}`}>
                    <NavButton
                      as={Link}
                      to={i.href}
                      active={history.location.pathname === i.href ? 'true' : ''}
                      className={i.hideMd ? 'hide-md' : ''}
                    >
                      {i.label}
                    </NavButton>
                    <Divider />
                  </Fragment>
                ) : i.label === 'Vitrine virtual' && enabledVirtualShowcase ? (
                  <Fragment key={`tabs-${i.href}`}>
                    <NavButton
                      as="a"
                      href="https://seminovos.instacarro.com/"
                      className="hide-md"
                      target="_blank"
                    >
                      Vitrine virtual
                      {enabledNewTag ? <img src={NewIcon} style={{ marginLeft: 4 }} /> : null}
                    </NavButton>
                    <Divider />
                  </Fragment>
                ) : (
                  i.label === 'Gestão de anúncios' &&
                  enabledAdvertisingManagement && (
                    <Fragment>
                      <NavButton
                        as="a"
                        href="https://ec2c-backoffice.instacarro.com/admin/login"
                        className="hide-md"
                        target="_blank"
                      >
                        Gestão de anúncios
                        {enabledNewTag ? <img src={NewIcon} style={{ marginLeft: 4 }} /> : null}
                      </NavButton>
                      <Divider />
                    </Fragment>
                  )
                )
              )}

          <Divider className="hide-md" />

          {loggedIn && (
            <SideMenu ref={sideMenuRef} showNotificationDot={isUserRegistrationOutdate}>
              <Flex flexDirection="column" flexBasis="100%" justifyContent="space-between">
                <Box>
                  <UserInfo
                    mb={2}
                    limitValue={fincreditInfo?.creditAvailableAmount}
                    showTag={displaySimulationBanner}
                  />
                  <Box className="show-md">
                    {enabledVirtualShowcase ? (
                      <SideMenuLink as="a" href="https://seminovos.instacarro.com/" target="_blank">
                        <Box as="img" src={VirtualShowcaseIcon} size={21} />
                        Vitrine virtual
                        {enabledNewTag ? <img src={NewIcon} /> : null}
                      </SideMenuLink>
                    ) : null}

                    {enabledAdvertisingManagement ? (
                      <SideMenuLink
                        as="a"
                        href="https://ec2c-backoffice.instacarro.com/admin/login"
                        target="_blank"
                      >
                        <Box as="img" src={AdvertisingManagementIcon} size={21} />
                        Gestão de anúncios
                        {enabledNewTag ? <img src={NewIcon} /> : null}
                      </SideMenuLink>
                    ) : null}
                  </Box>

                  <Box>
                    {displaySimulationBanner && (
                      <SideMenuLink
                        as="a"
                        href="/pagamento-facilitado/simule"
                        target="_blank"
                        onClick={() => trackFinCredit(new Date().getTime(), 'Nav Bar')}
                      >
                        <Box as="img" src={FinanIcon} /> Pagamento Facilitado
                      </SideMenuLink>
                    )}

                    {isUserRegistrationOutdate && (
                      <SideMenuLink as={Link} to="/atualizar-perfil">
                        <Box as={PersonIcon} size={21} /> Dados cadastrais
                        <Badge
                          variant="dot"
                          overlap="circular"
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          sx={{ right: '-20px' }}
                          invisible={!isUserRegistrationOutdate}
                        />
                      </SideMenuLink>
                    )}

                    {enabledOrderCars ? (
                      <SideMenuLink
                        onClick={() =>
                          showPreferenceButton || user.isNinja()
                            ? history.push('/preferencias')
                            : dispatch(showModal(PREFERENCES_CONFIG_KEY))
                        }
                      >
                        {user.isNinja() ? <RecommendedIcon /> : <Box as={FiEdit} size={21} />}
                        {copy}
                      </SideMenuLink>
                    ) : null}

                    <SideMenuLink as="a" href="https://www.instacarro.com/contato/" target="_blank">
                      <Box as={MdForum} size={21} /> Precisa de Ajuda?
                    </SideMenuLink>

                    <SideMenuLink onClick={handleChangePassword}>
                      <Box as={MdVpnKey} size={21} /> Mudar Senha
                    </SideMenuLink>

                    <Box className="show-md">
                      <SideMenuLink
                        as={Link}
                        to="/"
                        onClick={handleSideMenuLinkClick}
                        active={history.location.pathname === '/' ? 'true' : ''}
                      >
                        <Box as={MdGavel} mr={1} size={21} /> Disputas
                      </SideMenuLink>

                      <SideMenuLink
                        as={Link}
                        to="/favoritos"
                        onClick={handleSideMenuLinkClick}
                        active={history.location.pathname === '/favoritos' ? 'true' : ''}
                      >
                        <Box as={FaHeart} mr={1} size={21} /> Favoritos
                      </SideMenuLink>

                      <SideMenuLink
                        as={Link}
                        to="/compras"
                        onClick={handleSideMenuLinkClick}
                        active={history.location.pathname === '/compras' ? 'true' : ''}
                      >
                        <Box as={MdDriveEta} mr={1} size={21} /> Minhas compras
                      </SideMenuLink>

                      <SideMenuLink
                        as={Link}
                        to="/perfis"
                        onClick={handleSideMenuLinkClick}
                        active={history.location.pathname === '/perfis' ? 'true' : ''}
                      >
                        <Box as={MdSupervisorAccount} mr={1} size={21} /> Perfis
                      </SideMenuLink>

                      <SideMenuLink
                        className="hide-md"
                        as="a"
                        href={AppConstants.IndiqueLink}
                        target="_blank"
                      >
                        <Box as={MdAttachMoney} mr={1} size={21} /> Ganhe R$ 1.000
                      </SideMenuLink>
                    </Box>
                  </Box>
                </Box>

                <Button
                  width={1}
                  variant="ghost.default"
                  mt={2}
                  mb={{ sm: 3, md: 0 }}
                  onClick={handleLogout}
                >
                  Sair
                </Button>
              </Flex>
            </SideMenu>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
