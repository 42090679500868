import React from 'react';
import { FaDownload } from 'react-icons/fa';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
  Container,
  VehicleArea,
  BadgeAreaTitle,
  Row,
  Column,
  List,
  ListItem,
  CautelarContainer,
  CautelarButton,
  PesquisaLink,
} from './styles';

export const DocumentedModificationD2D = props => {
  const { updatedAuction } = props;

  const documentedMods = updatedAuction?.summary?.additionalInfo?.documentedModification || [];
  const nonDocumentedMods = updatedAuction?.summary?.additionalInfo?.noDocumentedModification || [];

  return (
    <Container>
      <VehicleArea>
        <Row>
          <Column>
            <BadgeAreaTitle>
              <InsertDriveFileIcon style={{ marginRight: '6px' }} />
              Modificação documentada
            </BadgeAreaTitle>
            <List>
              {documentedMods.length > 0 ? (
                documentedMods.map((mod, index) => (
                  <ListItem key={`doc-${index}`}>
                    <strong>{mod}</strong>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <strong>Nenhuma</strong>
                </ListItem>
              )}
            </List>
          </Column>

          <Column>
            <BadgeAreaTitle>
              {' '}
              <InsertDriveFileIcon style={{ marginRight: '6px' }} />
              Modificação NÃO documentada
            </BadgeAreaTitle>
            <List>
              {nonDocumentedMods.length > 0 ? (
                nonDocumentedMods.map((mod, index) => (
                  <ListItem key={`non-doc-${index}`}>
                    <strong>{mod}</strong>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <strong>Nenhuma</strong>
                </ListItem>
              )}
            </List>
          </Column>
        </Row>
      </VehicleArea>
      <CautelarContainer>
        <CautelarButton
          onClick={e => {
            e.preventDefault();
            if (updatedAuction?.laudo1) {
              window.open(updatedAuction?.laudo1, '_blank');
            }
          }}
        >
          <FaDownload size={16} style={{ marginRight: '8px' }} />
          Baixar Laudo Cautelar
        </CautelarButton>
        <PesquisaLink
          href="#"
          onClick={e => {
            e.preventDefault();
            if (updatedAuction?.laudo2) {
              window.open(updatedAuction?.laudo2, '_blank');
            }
          }}
        >
          Baixar Pesquisa Veicular
        </PesquisaLink>
      </CautelarContainer>
    </Container>
  );
};
