import styled from 'styled-components';

export const Container = styled.section`
  padding: 0 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  max-width: 1264px;

  @media screen and (max-width: 1025px) {
    align-items: flex-start;
  }
`;

export const VehicleArea = styled.div`
  width: 100%;
  border-top: 1px solid #eaebec;
  padding-top: 40px;
`;

export const BadgeArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 332px);
  align-items: flex-start;
  column-gap: 10px;

  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 316px);
    column-gap: 160px;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0 10px;
  width: 100%;
  max-width: 1200px;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 10px 0px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.6rem;
  background-color: red;
`;

export const AreaTitleContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

export const AreaTitle = styled.span`
  font-family: Rubik;
  color: #032470;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.64px;
  text-align: left;
  display: flex;
`;

export const AreaSubtitle = styled.span`
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21.64px;
  text-align: left;
  color: #757778;
  display: flex;
  margin-bottom: 10px;
`;

export const BadgeAreaSubtitle = styled.div`
  font-family: Rubik;
  font-size: 0.9rem;
  font-weight: 400;
  color: #2f3741;
  margin-top: 10px;
  a {
    color: #032470;
    text-decoration: underline;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px !important;
  font: 400 normal 16px/1.5em 'Roboto', sans-serif;
  color: #1a2331;
  text-align: end;
  border-bottom: 1px solid #eaebec;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  background: #000;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
